import type { Brand } from "./shared/types";

export const brand: Brand = {
  origins: [
    "https://evangelist.metaplex.com",
    "https://metaplex.dev.evangelist.gg",
    "http://domain3.test:3093",
  ],
  id: "metaplex",
  name: "Metaplex",
  logoUrl: "/logos/logo-metaplex.svg",
  designSchema: {
    darkMode: true,
    borderRadius: "8px",
    bgColor: "#1f1f1f",
    primaryColor: "#03FFBD",
    primaryColorContrast: "#000000",
    tweetComposer: {
      bgColor: "#343434",
      bgColorWithBonus: "#CFCBFF",
      positiveBulletColor: "#000",
      positiveBulletBackgroundColor: "#03FFBD",
    },
  },
  eligibilityDescriptions: {
    "not-eligible": [
      ""
    ],

    evaluating: [
      "This campaign is available for users who have staked at least 10 SOL with Marinade within the last 30 days."
    ],
  },
  campaigns: [
    {
      id: "1",
      advertiser: {
        isPreferred: true,
        id: "metaplex",
        name: "Metaplex",
        theme: "metaplex",
      },
      dateStart: "2024-12-23T12:00:00.000Z",
      dateEnd: "2025-01-02T16:00:00.000Z",
      dateMaturity: "2025-01-09T20:00:00.000Z",
      description:
        "Metaplex is the foundation for creating digital assets on Solana, powering everything from NFTs to memecoins",
      descriptionHtml:
        "<p>Metaplex is the foundation for creating digital assets on Solana, powering everything from NFTs to memecoins...</p>",

      budgetCurrency: "MPLX",
      walletAddress: "",
      state: "active",
      participated: false,
      // @ts-expect-error
      eligibility: {
        state: "NotInvited",
        restrictions: [
          {
            content: "Minimum Twitter followers: 500",
            contentHtml: "<p>Minimum Twitter followers: 500</p>\n",
          },
          {
            content: "Minimum account age (days): 365",
            contentHtml: "<p>Minimum account age (days): 365</p>\n",
          },
        ],
        additionalCampaignCheckType: "invitation-only",
      },
      title: "Spread the word about Metaplex and earn rewards",
      totalBudget: {
        amount: 19000,
        currency: "MPLX",
        estimatedValueUsd: 4750,
      },
      bonuses: [],
    },
  ],
};
