<script setup lang="ts">
import { cva } from "class-variance-authority";

withDefaults(defineProps<{ themeMode?: "light" | "dark" }>(), {
  themeMode: "light",
});

const evangelistButton = cva(
  [
    "flex gap-2 items-center group font-semibold text-xxs lg:text-sm border bg-gradient-to-b rounded-3xl py-2 px-2 lg:px-3",
  ],
  {
    variants: {
      themeMode: {
        dark: ["from-white/10 border-[#B9B9B9CC] text-white"],
        light: [" from-white/30 to-gray-200 border-gray-400"],
      },
    },
  },
);
</script>
<template>
  <a
    href="https://evangelist.gg"
    target="_blank"
    :class="evangelistButton({ themeMode })"
  >
    <IconsEvangelistIcon
      class="shrink-0 group-hover:rotate-[360deg] transition-all duration-500 ease-out"
    />
    <span class="hidden lg:block"> Ambasador program by Evangelist </span>
  </a>
</template>
