<script setup lang="ts">
import Reward from "./Reward.vue";
import TweetIcon from "~/components/Icons/TweetIcon.vue";
import SigninButton from "~/components/SigninButton.vue";
import AvailableReward from "./AvailableReward.vue";
import MasonryWall from "@yeger/vue-masonry-wall";
import { truncateString } from "~/utils/stringHelpers";
import type {
  InvitationCampaignDto,
  InvitationOverviewDto,
  InvitationPostDto,
} from "~~/_api_generated_";
import type { Brand } from "~/advertisorsData/shared/types";

interface Props {
  brand: Brand;
}

defineProps<Props>();

const overview = ref<InvitationOverviewDto>();
const campaign = ref<InvitationCampaignDto>();
const posts = ref<InvitationPostDto[]>([]);
const loading = ref(true);
const howDoesItWorkModalVisible = ref(false);
const route = useRoute();
const twitterUserName = route.query?.publicToken ?? null;

const recipient = computed(() => {
  return overview.value?.recipient;
});

const computedPosts = computed(() => {
  return posts.value; //.slice(0, 3);
});

function fetchData() {
  loading.value = true;
  if (typeof twitterUserName !== "string") {
    useRouter().push("/");
    return;
  }
  apiService
    .getInvitationDetails(twitterUserName)
    .then((response) => {
      overview.value = response.data.overview;
      campaign.value = response.data.campaign;
      posts.value = response.data.posts;
    })
    .catch((e) => {
      errorMessage(e);
      useRouter().push("/");
    })
    .finally(() => {
      loading.value = false;
    });
}

fetchData();
</script>

<template>
  <Modal
    v-if="howDoesItWorkModalVisible"
    @close="howDoesItWorkModalVisible = false"
  >
    <ContentsHowDoesItWorksSlider />
  </Modal>
  <div class="container-950 mt-8 lg:mt-14">
    <div class="text-4xl lg:text-6xl font-semibold">
      Your invitation to <br />
      {{ brand.name }} Ambassador<br />
      Program is here.
    </div>
  </div>
  <PageLoader v-if="loading" class="mt-14" />
  <div v-else>
    <div class="container-950 mt-8 lg:mt-14 flex items-center flex-wrap gap-3">
      <div class="bg-white rounded-2xl p-6 pt-5 font-semibold flex-auto">
        <AvailableReward v-if="campaign" :campaign="campaign" />
      </div>
      <div class="bg-white rounded-2xl p-6 pt-5 font-semibold flex-auto">
        <div class="">Create an ambassador profile at Evangelist</div>
        <div class="flex flex-wrap gap-4 items-center justify-between mt-8">
          <div class="flex items-center gap-2">
            <img
              :src="recipient?.profilePicUrl"
              alt=""
              class="rounded-full w-10 h-10"
            />
            <p class="text-2xl">@{{ recipient?.twitterHandle }}</p>
          </div>
          <SigninButton
            class="btn hover:bg-blac text-white mt-5 lg:mt-0"
            :style="{
              backgroundColor: brand.designSchema.primaryColor,
              borderRadius: brand.designSchema.borderRadius,
            }"
          />
        </div>
      </div>
    </div>

    <div class="container-950 mt-10 lg:mt-14 text-2xl font-semibold">
      Your {{ computedPosts.length }}
      {{ computedPosts.length == 1 ? "post" : "posts" }} about {{ brand.name }}
      {{ computedPosts.length == 1 ? "qualifies" : "qualify" }} for a
      retroactive reward.
      <button
        :style="{
          color: brand.designSchema.primaryColor,
        }"
        @click="howDoesItWorkModalVisible = true"
      >
        How does it work?
      </button>
    </div>

    <div
      v-if="computedPosts.length"
      class="container-950 mt-14 items-center gap-3"
    >
      <MasonryWall
        :items="computedPosts"
        :ssr-columns="1"
        :column-width="250"
        :gap="16"
      >
        <template #default="{ item }">
          <div
            class="p-6 bg-gradient-to-b from-transparent to-grey/20 font-semibold border border-grey rounded-2xl"
          >
            <div v-html="truncateString(item.content, 180)"></div>
            <div class="flex items-center justify-between mt-3">
              <div class="text-black/30">
                <TimeAgo :date="item.datePosted" />
                • {{ item.numImpressions }} impression{{
                  item.numImpressions > 1 ? "s" : ""
                }}
              </div>
              <TweetIcon />
            </div>
          </div>
        </template>
      </MasonryWall>
    </div>

    <!--        <TweetsCarousel :tweets="computedPosts"/>-->

    <div class="container-950 mt-14">
      <div
        class="flex items-center flex-wrap justify-between gap-6 rounded-2xl font-semibold"
      >
        <div>
          <div class="text-xl lg:text-2xl">
            This ambassador campaign<br />from {{ brand.name }} ends in
            <ExpiresIn v-if="campaign" :date="campaign.dateEnd" />
            .
          </div>
          <SigninButton
            class="mt-6 mb-4 lg:mb-0 lg:mt-4 btn text-white fill-white hover:bg-black"
            :style="{
              backgroundColor: brand.designSchema.primaryColor,
              borderRadius: brand.designSchema.borderRadius,
            }"
          />
        </div>
        <div class="pr-12">
          <Reward v-if="campaign" :campaign="campaign" type="extended" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
