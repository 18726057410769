import { NotificationType } from "~/common/Enums";
import type {
  AuthProfileDto,
  NotificationDto,
  PayoutDto,
} from "~~/_api_generated_";

interface State {
  user: AuthProfileDto;
  loginLoading: boolean;
  notifications: NotificationDto[];
  notificationSidebarOpen: boolean;
  paychecks: PayoutDto[];
}
const defaultUserState = {
  userId: null,
  profileImageUrl: null,
  twitterHandle: false,
  userName: false,
  userRoles: [],
  twoFactorAuthEnabled: false,
  verifiedTwoFactorAuthEnabled: false,
  email: null,
  verifiedEmail: null,
};

const allowedEventTypes = [
  NotificationType.WELCOME,
  NotificationType.CAMPAIGN_JOINED,
  NotificationType.CAMPAIGN_ENDED,
  NotificationType.CAMPAIGN_ELIGIBLE,
  NotificationType.CAMPAIGN_NOT_ELIGIBLE,
  NotificationType.CAMPAIGN_MATURITY,
  NotificationType.NEW_ELIGIBLE_POSTS,
  // NotificationType.NEW_REWARDED_POSTS,
  NotificationType.TRANSACTION_VERIFYING,
  NotificationType.TRANSACTION_COMPLETED,
  NotificationType.TERMS_CONDITIONS,
  NotificationType.AD_HOC,
];

export const useDefaultStore = defineStore("default", {
  state: (): State => ({
    // @ts-expect-error this is expected error because the default state is parially nullable
    user: {
      ...defaultUserState,
    },
    loginLoading: false,
    notifications: [],
    notificationSidebarOpen: false,
    paychecks: [],
  }),
  actions: {
    openNotificationSidebar() {
      this.notificationSidebarOpen = true;
    },

    closeNotificationSidebar() {
      this.notificationSidebarOpen = false;
    },

    loadUser() {
      const response = apiService.getCurrentUser();

      response.then((res) => {
        const user = res.data;
        this.user = user;
        trackUser(user.userId);
        // }).catch(e => {
        //     errorMessage("Error! Can not get current user.")
      });

      return response;
    },

    refreshNotifications() {
      const response = apiService.refreshNotifications();

      response
        .then((res) => {
          let notifications = res.data;
          notifications = notifications.filter((i) =>
            allowedEventTypes.includes(i.eventType as NotificationType),
          );

          this.notifications = notifications;
        })
        .catch(() => {
          errorMessage("Error! Can not get notifications.");
        });

      return response;
    },

    clearUser() {
      // @ts-expect-error this is expected error because the default state is parially nullable
      this.user = defaultUserState;
    },

    loadPaychecks() {
      const response = apiService.loadPaychecks();

      response
        .then((res) => {
          const paycheks = res.data;
          this.paychecks = paycheks;
        })
        .catch(() => {
          errorMessage("Error! Can not get paycheks.");
        });

      return response;
    },
  },

  getters: {
    computedNotifications(state): NotificationDto[] {
      return state.notifications.filter(
        (i) => !["welcome"].includes(i.eventType),
      );
    },

    notificationCount(): number {
      return (this.computedNotifications ?? []).filter((i) => !i.isRead).length;
    },

    ftuNotification(state): NotificationDto | undefined {
      return (state.notifications ?? []).find(
        (i) => !i.isRead && (
          i.eventType == "welcome"
        ),
      );
    },

    campaignEligibleNotifications(state): NotificationDto[] {
      return (state.notifications ?? []).filter(
        (i) => !i.isRead && i.eventType == "campaign-eligible",
      );
    },
  },
});
