import type { Brand } from "./shared/types";

export const brand: Brand = {
  origins: [
    "https://evangelist.switchboard.foundation",
    "https://switchboard.dev.evangelist.gg",
    "http://domain4.test:3094",
  ],
  id: "switchboard",
  name: "Switchboard",
  logoUrl: "/logos/logo-switchboard.svg",
  designSchema: {
    borderRadius: "8px",
    bgColor: "#e9f1f4",
    primaryColor: "#2d45eb",
    primaryColorContrast: "#ffffff",
    tweetComposer: {
      bgColor: "#ffffff",
      positiveBulletColor: "#ffffff",
      positiveBulletBackgroundColor: "#2d45eb",
    },
  },
  eligibilityDescriptions: {
    "not-eligible": [],
    evaluating: [],
  },
  campaigns: [
    {
      id: "3ea7cee3-49ca-4242-a91e-d912bfc3fc2f",
      advertiser: {
        isPreferred: true,
        id: "15500f29-27ae-4047-9ad2-88942037cc74",
        name: "Switchboard",
        theme: "switchboard",
      },
      dateStart: "2024-11-28T00:00:00.000Z",
      dateEnd: "2024-12-05T00:00:00.000Z",
      dateMaturity: "2024-12-12T00:00:00.000Z",
      description:
        "Explain Switchboard in the simplest way, like you're explaining it to someone completely new to blockchain. Help beginners understand what Switchboard is, how it works, and why its decentralized oracle network is valuable for other apps.",

      descriptionHtml:
        "Explain Switchboard in the simplest way, like you're explaining it to someone completely new to blockchain. Help beginners understand what Switchboard is, how it works, and why its decentralized oracle network is valuable for other apps.",
      budgetCurrency: "SWTCH",
      state: "active",
      participated: false,
      apyBonus: 0,
      eligibility: {
        state: "NotInvited",
        restrictions: [
          {
            content: "Minimum Twitter followers: 500",
            contentHtml: "<p>Minimum Twitter followers: 500</p>\n",
          },
          {
            content: "Minimum account age (days): 365",
            contentHtml: "<p>Minimum account age (days): 365</p>\n",
          },
        ],

        // @ts-expect-error
        additionalCampaignCheckType: "",
      },
      title: "Spread the word about Switchboard and earn rewards",
      totalBudget: {
        amount: 35000,
        currency: "SWTCH",
        // @ts-expect-error This is a intentional string usage since the token didn't go public yet
        estimatedValueUsd: "Token Generation Event in 2025",
      },
    },
  ],
};
