import type { Brand, BrandName } from "./types";

export async function getBrand(brand: BrandName): Promise<Brand> {
  if (brand === "marinade") {
    const marinade = await import("../marinade");
    return marinade.brand;
  }

  if (brand === "switchboard") {
    const switchboard = await import("../switchboard");
    return switchboard.brand;
  }

  if (brand === "metaplex") {
    const metaplex = await import("../metaplex");
    return metaplex.brand;
  }

  throw new Error(`Brand ${brand} not found`);
}

export function getBrandNameForOrigin(origin: string): BrandName | undefined {
  if (
    origin === "https://evangelist.marinade.finance" ||
    origin === "https://marinade.dev.evangelist.gg" ||
    origin === "http://domain2.test:3092"
  ) {
    return "marinade";
  }

  if (
    origin === "https://evangelist.metaplex.com" ||
    origin === "https://metaplex.dev.evangelist.gg" ||
    origin === "http://domain3.test:3093"
  ) {
    return "metaplex";
  }

  if (
    origin === "https://evangelist.switchboard.foundation" ||
    origin === "https://switchboard.dev.evangelist.gg" ||
    origin === "http://domain4.test:3094"
  ) {
    return "switchboard";
  }


  return undefined;
}
