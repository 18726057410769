<script setup lang="ts">
import type {
  SuggestedCampaignDto,
  InvitationCampaignDto,
} from "~~/_api_generated_";
import { prepareCurrencies } from "~/utils/currencies";
interface Props {
  campaign: SuggestedCampaignDto | InvitationCampaignDto;
  type: string;
}

const props = withDefaults(defineProps<Props>(), {
  type: "default",
});

const campaign = computed(() => {
  return props.campaign;
});

const selectedCurrency = ref(props.campaign.budgetCurrency);

const campaignCurrencies = computed(() => {
  return prepareCurrencies(campaign.value?.totalBudget);
});

const selectedCurrencyAmount = computed(() => {
  return campaignCurrencies.value[selectedCurrency.value];
});
</script>

<template>
  <div v-if="type == 'default'">
    <div class="flex items-center justify-between font-semibold">
      <span class="text-black/50">Your available rewards</span>
      <div class="select-gray-wrapper">
        <select v-model="selectedCurrency" class="select-gray">
          <option
            v-for="currency in Object.keys(campaignCurrencies)"
            :key="currency"
            :value="currency"
          >
            {{ currency }}
          </option>
        </select>
      </div>
    </div>
    <div class="mt-4 text-5xl font-bold">
      <span>{{ selectedCurrencyAmount }}</span>
      <span class="text-2xl uppercase ml-2">{{ selectedCurrency }}</span>
    </div>
  </div>
  <div v-else>
    <div class="">Total Budget</div>
    <div class="flex gap-2 items-end my-2 text-5xl font-bold">
      <span>{{ selectedCurrencyAmount }}</span>
      <span class="text-2xl uppercase">{{ selectedCurrency }}</span>
    </div>
    <div class="text-black/50" v-if="selectedCurrency === 'SWTCH'">
      Token Generation Event in 2025
    </div>
    <div class="text-black/50" v-if="selectedCurrency !== 'SWTCH'">
      Approx. {{ prepareCurrencies(campaign.totalBudget)["USD"] }}
    </div>
  </div>
</template>

<style scoped></style>
