import type { Brand } from "./shared/types";

export const brand: Brand = {
  origins: [
    "https://evangelist.marinade.finance",
    "https://marinade.dev.evangelist.gg",
    "http://domain2.test:3092",
  ],
  id: "marinade",
  name: "Marinade",
  logoUrl: "/logos/logo-marinade.svg",
  designSchema: {
    borderRadius: "24px",
    bgColor: "#f3f7fa",
    primaryColor: "#447a77",
    primaryColorContrast: "#ffffff",
    tweetComposer: {
      bgColor: "#baddd3",
      bgColorWithBonus: "#CFCBFF",
      positiveBulletColor: "#000",
      positiveBulletBackgroundColor: "#fff",
    },
  },
  eligibilityDescriptions: {
    "not-eligible": [
      "This campaign is available for users who have staked at least 10 SOL with Marinade within the last 30 days.",
      "Please connect the wallet you've staked with on Marinade, to verify your eligibility.",
    ],

    evaluating: [
      "This campaign is available for users who have staked at least 10 SOL with Marinade within the last 30 days.",
      "Please connect the wallet you’ve staked with on Marinade, to verify your eligibility. It can take up to 5 minutes to verify your eligibility.",
    ],
  },
  campaigns: [
    {
      id: "91805d2a-dcac-4e1c-a979-378dd8204faa",
      advertiser: {
        isPreferred: true,
        id: "15500f29-27ae-4047-9ad2-88942037cc74",
        name: "Marinade",
        theme: "marinade",
      },
      dateStart: "2024-11-28T00:00:00.000Z",
      dateEnd: "2024-12-05T00:00:00.000Z",
      dateMaturity: "2024-12-12T00:00:00.000Z",
      description:
        'Every week we will ask you a simple question. Answer it in a post on X and get 1% APY a week from your verified wallets. \n\nQuestion of the week: \n"How did you choose between Native and Liquid staking?"\n\nExample post: "I chose @MarinadeFinance for liquid staking due to its flexibility. It allows me to earn yield while keeping my assets liquid for DeFi use. Native staking offers greater security, so the choice depends on your preference for liquidity versus commitment. #MarinadeFinance"',
      descriptionHtml:
        "<p>Every week we will ask you a simple question. Answer it in a post on X and get 1% APY a week from your verified wallets.</p>\n<p>Question of the week:\n&quot;How did you choose between Native and Liquid staking?&quot;</p>\n<p>Example post: &quot;I chose @MarinadeFinance for liquid staking due to its flexibility. It allows me to earn yield while keeping my assets liquid for DeFi use. Native staking offers greater security, so the choice depends on your preference for liquidity versus commitment. #MarinadeFinance&quot;</p>\n",
      budgetCurrency: "MNDE",
      walletAddress:
        "https://solscan.io/account/89SrbjbuNyqSqAALKBsKBqMSh463eLvzS4iVWCeArBgB",
      state: "active",
      participated: false,
      // @ts-expect-error
      eligibility: {
        state: "NotInvited",
        restrictions: [
          {
            content: "Minimum Twitter followers: 500",
            contentHtml: "<p>Minimum Twitter followers: 500</p>\n",
          },
          {
            content: "Minimum account age (days): 365",
            contentHtml: "<p>Minimum account age (days): 365</p>\n",
          },
          {
            content: "Additional wallet checks",
            contentHtml: "<p>Additional wallet checks</p>\n",
          },
        ],
        additionalCampaignCheckType: "external-wallet-check",
      },
      title: "Share your experience staking with Marinade",
      apyBonus: 1,
      signupBonus: 500,
      bonuses: [],
    },
    {
      id: "3ea7cee3-49ca-4242-a91e-d912bfc3fc2f",
      advertiser: {
        isPreferred: true,
        id: "15500f29-27ae-4047-9ad2-88942037cc74",
        name: "Marinade",
        theme: "marinade",
      },
      dateStart: "2024-11-28T00:00:00.000Z",
      dateEnd: "2024-12-05T00:00:00.000Z",
      dateMaturity: "2024-12-12T00:00:00.000Z",
      description:
        'Help us craft a compelling message on X about the benefits of Marinade Liquid and Native staking\n\nExample post: "Staking my SOL with @MarinadeFinance has been fantastic – native staking, self-custody, and automatic delegation to 100+ best validators. Solid 4-year track record with TVL of 8M $SOL! APY over 11% 🚀 #Solana #Staking"\n\nAdditional talking points:\n- 100% uptime guarantee backed by staking reward insurance\n- Native staking, no custody risk',
      descriptionHtml:
        "<p>Help us craft a compelling message on X about the benefits of Marinade Liquid and Native staking</p>\n<p>Example post: &quot;Staking my SOL with @MarinadeFinance has been fantastic – native staking, self-custody, and automatic delegation to 100+ best validators. Solid 4-year track record with TVL of 8M $SOL! APY over 11% 🚀 #Solana #Staking&quot;</p>\n<p>Additional talking points:</p>\n<ul>\n<li>100% uptime guarantee backed by staking reward insurance</li>\n<li>Native staking, no custody risk</li>\n</ul>\n",
      budgetCurrency: "MNDE",
      walletAddress:
        "https://solscan.io/account/89SrbjbuNyqSqAALKBsKBqMSh463eLvzS4iVWCeArBgB",
      state: "active",
      participated: false,
      eligibility: {
        state: "NotInvited",
        restrictions: [
          {
            content: "Minimum Twitter followers: 500",
            contentHtml: "<p>Minimum Twitter followers: 500</p>\n",
          },
          {
            content: "Minimum account age (days): 365",
            contentHtml: "<p>Minimum account age (days): 365</p>\n",
          },
        ],

        // @ts-expect-error
        additionalCampaignCheckType: "",
      },
      title: "Spread the word about Marinade and earn MNDE",
      totalBudget: {
        amount: 105000,
        currency: "MNDE",
        estimatedValueUsd: 15000,
      },
      bonuses: [],
    },
  ],
};
