<script setup lang="ts">
import type { NotificationDto } from "~~/_api_generated_";

interface Props {
  params: NotificationDto["callToActionParam"];
}

const props = defineProps<Props>();

const params = computed(() => props.params);

const startDate = computed(() => {
  return new Date(params.value.campaignStartDate);
});

const endDate = computed(() => {
  return new Date(params.value.campaignEndDate);
});

const dates = computed(() => {
  return getCampaignDateStatus(startDate.value, endDate.value);
});

const progressBar = computed(() => {
  const isUrgent = false;

  return {
    label: "Campaign ends in " + dates.value.endsIn,
    width: progressBarWidthUntillEndDate.value,
    barClass: isUrgent ? "bg-red-light" : "bg-purple-light",
    labelClass: isUrgent ? "text-red" : "",
    urgent: isUrgent,
  };
});

const progressBarWidthUntillEndDate = computed(() => {
  const totalDuration = endDate.value.getTime() - startDate.value.getTime();
  const timePassed = new Date().getTime() - startDate.value.getTime();

  if (totalDuration === 0) {
    return "0%";
  }

  const timePassedRatio = timePassed / totalDuration;

  if (timePassedRatio > 1) {
    return "100%";
  }

  return timePassedRatio * 100 + "%";
});
</script>

<template>
  <div class="relative w-9/12 bg-black/10 rounded-lg">
    <span
      class="absolute block h-full rounded-lg"
      :class="progressBar?.barClass"
      :style="{ width: progressBar.width }"
    ></span>
    <div class="relative p-1.5 px-3 flex gap-1" :class="progressBar.labelClass">
      <IconsFireIcon v-if="progressBar.urgent" />
      {{ progressBar.label }}
    </div>
  </div>
</template>
