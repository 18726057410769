export enum CampaignState {
  INACTIVE = "inactive",
  ACTIVE = "active",
  ENDING = "ending",
  EVALUATING = "evaluating",
  EVALUATED = "evaluated",
}

export enum TweetRewardState {
  EVALUATING = "evaluating",
  ELIGIBLE = "eligible",
  ELIGIBLE_BONUS = "eligible-bonus",
  NOT_ELIGIBLE = "not-eligible",
  EXPIRED = "expired",
  EXPIRED_BONUS = "expired-bonus",
  CLAIMED = "claimed",
  REWARDED = "rewarded",
  NOT_REWARDED = "not-rewarded",
  PAID = "paid",
  PAID_BONUS = "paid-bonus",
}

export enum WithdrawalState {
  PENDING = "pending",
  PROCESSING = "processing",
  CANCELLED = "cancelled",
  COMPLETED = "completed",
  FAILED = "failed",
}

export enum ReceivedTweetRewardState {
  DISCARDED = "Discarded",
  EVALUATING = "Evaluating",
  NOT_ELIGIBLE = "NotEligible",
  ELIGIBLE = "Eligible",
  ELIGIBLE_BONUS = "EligibleBonus",
  EXPIRED = "Expired",
  EXPIRED_BONUS = "ExpiredBonus",
  NOT_REWARDED = "NotRewarded",
  REWARDED = "Rewarded",
}

export enum SentTweetRewardState {
  CLAIMED = "claimed",
  PAID = "paid",
}

export enum PostOrigin {
  X = "x",
  EVANGELIST = "evangelist",
}

export enum WalletState {
  VERIFIED = "verified",
}

export enum NotificationType {
  WELCOME = "welcome",
  CAMPAIGN_INVITED = "campaign-invited",
  CAMPAIGN_STARTED = "campaign-started",
  CAMPAIGN_JOINED = "campaign-joined",
  CAMPAIGN_ELIGIBLE = "campaign-eligible",
  CAMPAIGN_NOT_ELIGIBLE = "campaign-not-eligible",
  CAMPAIGN_ENDING = "campaign-ending",
  CAMPAIGN_ENDED = "campaign-ended",
  CAMPAIGN_MATURITY = "campaign-maturity",
  NEW_ELIGIBLE_POSTS = "posts-eligible",
  NEW_REWARDED_POSTS = "posts-rewarded",
  TRANSACTION_VERIFYING = "transaction-verifying",
  TRANSACTION_PENDING = "transaction-pending",
  TRANSACTION_CANCELLED = "transaction-cancelled",
  TRANSACTION_FAILED = "transaction-failed",
  TRANSACTION_COMPLETED = "transaction-completed",
  TERMS_CONDITIONS = "terms-and-conditions",
  AD_HOC = "ad-hoc-notification",
}
