import { ApplicationInsights } from "@microsoft/applicationinsights-web";

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();
  const appInsightsString = config.public.APP_INSIGHTS_CONNECTION_STRING;

  const appInsightsDisabledForDevelopment =
    appInsightsString === "disabled" && config.public.IS_DEVELOPMENT;
  if (appInsightsString && !appInsightsDisabledForDevelopment) {
    const defaultStore = useDefaultStore();
    const user = defaultStore.user;

    const appInsights = new ApplicationInsights({
      config: {
        connectionString: appInsightsString,
        enableAutoRouteTracking: true,
        enableCorsCorrelation: true,
        endpointUrl:
          config.public.API_ENDPOINT + "/application-insights-forwarding",
        userOverrideEndpointUrl:
          config.public.API_ENDPOINT + "/application-insights-forwarding",
      },
    });

    appInsights.loadAppInsights();
    // @ts-expect-error
    window.appInsights = appInsights;

    function isChromeExtensionError(error: any) {
      if (!error || !error.stack) return false;

      return error.stack.includes("chrome-extension://");
    }

    nuxtApp.hook("vue:error", (error, instance, info) => {
      const isFromChromeExtension = isChromeExtensionError(error);

      appInsights.trackException(error as any, {
        component: instance?.$options?.name || "Anonymous Component",
        info: info,
        source: isFromChromeExtension ? "Chrome Extension" : "Application",
        originator: "Vue Error Hook",
        userId: user.userId,
        twitterHandle: user.twitterHandle,
        message: (error as any).message || "No message provided",
        stack: (error as any).stack || "No stack provided",
      });
    });

    nuxtApp.hook("app:error", (error) => {
      const isFromChromeExtension = isChromeExtensionError(error);

      appInsights.trackException(error as any, {
        originator: "App Error Hook",
        source: isFromChromeExtension ? "Chrome Extension" : "Application",
        userId: user.userId,
        twitterHandle: user.twitterHandle,
        message: (error as any).message || "No message provided",
        stack: (error as any).stack || "No stack provided",
      });
    });

    return {
      provide: {
        appInsights,
      },
    };
  }
});
