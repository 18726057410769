<script setup lang="ts">
const props = defineProps({
  showTooltip: {
    type: Boolean,
    default: true,
  },
});

const showTooltip = computed(() => {
  return props.showTooltip;
});
</script>

<template>
  <div
    class="text-white bg-black flex justify-center items-center rounded-full shrink-0 w-full aspect-square"
  >
    <svg
      width="14"
      height="9"
      viewBox="0 0 14 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9607 8.55738C13.9863 8.602 13.9999 8.65258 14 8.70411C14.0001 8.75563 13.9869 8.8063 13.9615 8.85106C13.9362 8.89583 13.8997 8.93313 13.8556 8.95926C13.8115 8.98539 13.7614 8.99943 13.7102 9H11.1874C11.1365 8.99943 11.0867 8.98553 11.0427 8.95966C10.9988 8.93379 10.9623 8.89684 10.9369 8.85246L6.03926 0.442623C6.01368 0.398003 6.00014 0.347417 6 0.295892C5.99986 0.244368 6.01311 0.1937 6.03845 0.148937C6.06378 0.104173 6.10032 0.0668739 6.14441 0.040744C6.18851 0.014614 6.23863 0.000566282 6.28979 0H8.82284C8.87372 0.000566839 8.92359 0.0144681 8.96752 0.0403387C9.01144 0.0662093 9.04792 0.103157 9.07336 0.147541L13.9607 8.55738Z"
        fill="white"
      />
      <path
        d="M6.95811 6.45316C6.98749 6.50306 7.00195 6.5603 6.99979 6.61812C6.99763 6.67594 6.97894 6.73195 6.94593 6.77954L5.48116 8.8729C5.45182 8.91384 5.41274 8.94687 5.36742 8.96901C5.3221 8.99116 5.27198 9.00173 5.22155 8.99977C5.17112 8.99781 5.12197 8.98339 5.07851 8.9578C5.03506 8.93221 4.99866 8.89626 4.9726 8.85317L0.0408085 0.45541C0.0142185 0.409501 0.000150079 0.357453 1.19389e-06 0.30444C-0.000147691 0.251428 0.0136311 0.199296 0.0399628 0.153239C0.0662944 0.107183 0.104261 0.0688059 0.150089 0.041921C0.195918 0.0150362 0.248011 0.000582641 0.301181 0H2.93989C2.99278 0.000583214 3.0446 0.0148861 3.09026 0.0415041C3.13591 0.068122 3.17382 0.106137 3.20026 0.151803L6.95811 6.45316Z"
        fill="white"
      />
      <path
        d="M1.96616 8.49859C1.98815 8.54901 1.99982 8.60617 2 8.6644C2.00018 8.72263 1.98887 8.77991 1.96719 8.83057C1.94552 8.88123 1.91422 8.92351 1.87641 8.95323C1.83861 8.98295 1.79559 8.99907 1.75163 9H0.252396C0.185457 9 0.121258 8.96478 0.0739249 8.90209C0.0265915 8.8394 0 8.75438 0 8.66572V5.32296C0.00182758 5.25151 0.0208998 5.18273 0.0544183 5.1267C0.0879368 5.07066 0.134135 5.03034 0.186232 5.01164C0.238328 4.99294 0.293577 4.99685 0.343871 5.0228C0.394165 5.04875 0.436856 5.09537 0.465671 5.15582L1.96616 8.49859Z"
        fill="white"
      />
    </svg>
    <tippy v-if="showTooltip" to="parent" :hide-on-click="false" interactive>
      <strong>Media sponsor:</strong> The Metaplex Foundation<br />
      <strong>Country:</strong> Cayman Islands<br />
      <strong>Contact:</strong>
      <a target="_blank" href="mailto:ops@metaplex.foundation" class="underline"
        >ops@metaplex.foundation</a
      >
    </tippy>
  </div>
</template>
