<script setup lang="ts">
import type { NuxtError } from "#app";
const props = defineProps<{ error: NuxtError }>();
const handleError = () => clearError({ redirect: "/" });

const randomUUID = crypto.randomUUID();

const defaultStore = useDefaultStore();

const user = defaultStore.user;
const isFromExtension = props.error?.stack?.includes("chrome-extension://");

const isServerError = computed(() => {
  return props.error.statusCode >= 500;
});

console.log(props.error);

onMounted(() => {
  if ((window as any).appInsights && isServerError.value) {
    (window as any).appInsights.trackException(props.error as any, {
      originator: "Global Error.vue component capture",
      source: isFromExtension ? "Chrome Extension" : "Application",
      userId: user.userId,
      twitterHandle: user.twitterHandle,
      message: props.error.statusMessage || "No message provided",
      stack: props.error.stack || "No stack provided",
      statusCode: props.error.statusCode || "No status code provided",
      incidentId: randomUUID,
    });
  }
});
</script>

<template>
  <div class="text-sm font-semibold">
    <AppHeader />
    <div class="mt-4">
      <div class="container">
        <div class="flex flex-col items-center mt-20 lg:mt-64 text-center">
          <div class="text-5xl">{{ error?.statusMessage }}</div>
          <div v-if="isServerError" class="text-sm mt-4">
            {{ new Date().toUTCString() }}
          </div>
          <div v-if="isServerError" class="text-sm">
            Incident Id: {{ randomUUID }}
          </div>
          <div class="text-3xl mt-6">Error: {{ error?.statusCode }}</div>
          <button
            class="btn mt-4 bg-purple-light hover:bg-black hover:text-white"
            @click="handleError"
          >
            Go to dashboard
          </button>
        </div>
      </div>
    </div>
    <AppFooter />
  </div>
</template>
