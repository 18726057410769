<script setup lang="ts">
// 1. Open side panel
// 	1.1   EvaluationPending
// 	1.2   Evaluating
// 	1.3   NotEligible
// 	1.4   NotEligibleBasic
// 2. Does nothing
// 	2.1   New
// 	2.2   NotInvited
// 3. Go to campaign
// 	3.1   EligibleBasic
// 	3.2   Eligible

import type { SuggestedCampaignDto } from "~~/_api_generated_";
import { useRouter } from "vue-router";
import { cva } from "class-variance-authority";

interface Props {
  campaign: SuggestedCampaignDto;
  inModal?: boolean;
  landingPageMode?: boolean;
  customTotalBudgetText?: string;
  isFirst?: boolean;
  isDark?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  inModal: false,
  landingPageMode: false,
  isFirst: false,
  isDark: false,
  customTotalBudgetText: "Total Budget",
});

const emit = defineEmits(["openSidebar"]);
const router = useRouter();

const inModal = computed(() => {
  return props.inModal;
});

const firstRow = cva(
  ["border-[#CFCBFF] bg-[#CFCBFF] outline-none border-none"],
  {
    variants: {
      isClickable: {
        true: [
          "cursor-pointer bg-gradient-to-b hover:from-white hover:to-white hover:border-white",
        ],
        false: null,
      },
    },
  },
);

const row = cva([], {
  variants: {
    inModal: {
      true: "bg-gradient-to-b from-gray-100 to-gray-200 border border-gray-300 campaign-row",
      false:
        "border border-white bg-gradient-to-b from-white/50 to-transparent",
    },
    isClickable: {
      true: ["cursor-pointer"],
      false: "",
    },
    isDark: {
      true: "border-[#B9B9B9CC] text-white to-transparent !from-[#B0B0B0]/20",
      false: "",
    },
  },
  compoundVariants: [
    {
      inModal: true,
      isClickable: true,
      class: "hover:from-white hover:to-white hover:border-white",
    },
    {
      inModal: false,
      isClickable: true,
      class: "hover:bg-white hover:shadow-blur",
    },
  ],
});

const isClickable = computed(() => {
  return (
    ["New", "NotInvited"].includes(props.campaign.eligibility.state) === false
  );
});

function resolveClick() {
  if (
    props.campaign.eligibility.state === "Eligible" ||
    props.campaign.eligibility.state === "EligibleBasic"
  ) {
    router.push(`/campaign/${props.campaign.id}`);
  }

  if (props.campaign.eligibility.state === "NotEligibleBasic") {
    globalEmit("campaignForSidebarClicked", {
      campaignToOpen: props.campaign,
      seeCriteria: true,
    });
  }

  if (
    ["Evaluating", "NotEligible", "EvaluationPending"].includes(
      props.campaign.eligibility.state,
    )
  ) {
    globalEmit("campaignForSidebarClicked", {
      campaignToOpen: props.campaign,
      seeCriteria: false,
    });
  }

  if (
    props.campaign.eligibility.state === "NotEligible" ||
    props.campaign.eligibility.state === "NotEligibleBasic"
  ) {
    trackEvent("not_eligible_campaign_clicked", {
      campaignId: props.campaign.id,
    });
  }
}
</script>

<template>
  <NuxtLink
    v-if="
      props.campaign.eligibility.state === 'Eligible' ||
      props.campaign.eligibility.state === 'EligibleBasic'
    "
    :to="`/campaign/${props.campaign.id}`"
  >
    <CampaignRowContent
      :class="[
        isFirst
          ? firstRow({ isClickable })
          : row({ isClickable, inModal, isDark }),
      ]"
      v-bind="props"
    />
  </NuxtLink>

  <div v-else @click="resolveClick">
    <CampaignRowContent
      :class="[
        isFirst
          ? firstRow({ isClickable })
          : row({ isClickable, inModal, isDark }),
      ]"
      v-bind="props"
    />
  </div>
</template>
