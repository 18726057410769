<script setup lang="ts"></script>

<template>
  <div class="flex items-center gap-2 shrink-0">
    <div class="w-6">
      <BrandsMetaplexIcon :show-tooltip="false" />
    </div>
    Metaplex
    <tippy to="parent" :hide-on-click="false" interactive>
      <strong>Media sponsor:</strong> The Metaplex Foundation<br />
      <strong>Country:</strong> Cayman Islands<br />
      <strong>Contact:&nbsp;</strong>
      <a target="_blank" href="mailto:ops@metaplex.foundation" class="underline"
        >ops@metaplex.foundation</a
      >
    </tippy>
  </div>
</template>
