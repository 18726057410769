export function truncateString(str: string, length: number) {
  return str.length > length ? str.substring(0, length) + "..." : str;
}

export function smartTruncate(input: string, maxLength: number = 160): string {
  if (input.length <= maxLength) {
    return input;
  }

  // Find the last space before the cutoff point
  let truncated = input.slice(0, maxLength + 1); // +1 to ensure we don't cut a word in the middle
  const lastSpaceIndex = truncated.lastIndexOf(" ");

  // If there's a space, truncate up to it; otherwise, return the original slice
  if (lastSpaceIndex !== -1) {
    truncated = truncated.slice(0, lastSpaceIndex);
  }

  return truncated + "..."; // Append ellipsis to indicate truncation
}
